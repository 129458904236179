.wrapper {
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
}
.header {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 15vh;
	background-color: var(--primary);
}
.header p {
	font-size: calc(var(--paragraph-size) + 1vw);
	font-style: italic;
	color: var(--paragraph-color);
}
.body {
	position: relative;
	display: flex;
	width: 100%;
}
.imgWrapper {
	margin-top: 3%;
}
.bodyWrapper p {
	font-size: var(--paragraph-size);
	color: var(--paragraph-color);
	font-style: italic;
	padding: 5%;
	line-height: 2vw;
}
.bodyWrapper a {
	height: fit-content;
	width: fit-content;
	margin-top: 5%;
	margin-left: 5%;
	color: var(--primary);
	font-size: var(--paragraph-size);
}
.bodyWrapper a:hover {
	cursor: pointer;
	text-decoration: underline;
}
.bodyWrapper svg {
	color: red;
	margin-top: 5%;
}
.bodyWrapper div > div {
	background-color: lightgreen;
	padding: 0.7% 2.5%;
	width: fit-content;
	height: fit-content;
	border-radius: 50px;
	margin-top: 5%;
	margin-left: 5%;
	font-size: calc(var(--paragraph-size) - 0.4vw);
}
.closeModal {
	color: var(--paragraph-color);
	transition: ease-in-out 0.2s;
}
.closeModal:hover {
	cursor: pointer;
	color: red;
}
@media screen and (max-width: 400px) {
	.imgWrapper img {
		display: none;
	}
	.bodyWrapper p {
		line-height: 4.8vw;
	}
}
@media screen and (max-width: 850px) and (min-width: 400px) {
	.imgWrapper img {
		display: none;
	}
	.bodyWrapper p {
		line-height: 2.4vw;
	}
}
