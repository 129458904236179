.topbar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 95%;
	align-items: center;
	margin-top: 3%;
}
.topBarList {
	list-style-type: none;
	margin-right: 5%;
}
.topBarList li {
	display: inline;
	padding-left: 10%;
	padding-right: 10%;
}

.topBarList li:hover {
	font-weight: bold;
	cursor: pointer;
}

.topBarList li a {
	color: var(--primary);
	text-decoration: none;
	font-size: 1.2vw;
}
.logo {
	width: 8%;
}
#path815 {
	fill: var(--primary);
}

@media screen and (max-width: 400px) {
	.topBarList {
		margin-right: 15%;
	}
	.topBarList li {
		padding-left: 5%;
		padding-right: 5%;
	}
	.topBarList li a {
		color: var(--primary);
		text-decoration: none;
		font-size: 3.5vw;
	}
	.logo {
		width: 25%;
	}
	.topBarList.hideIfMobile {display: none;}
}

@media screen and (max-width: 850px) and (min-width: 400px) {
	.topBarList {
		margin-right: 8%;
	}
	.topBarList li {
		padding-left: 5%;
		padding-right: 5%;
	}
	.topBarList li a {
		color: var(--primary);
		text-decoration: none;
		font-size: 2.3vw;
	}
	.logo {
		width: 15%;
	}
}
