.wrapper {
	display: grid;
	place-items: center;
	width: 100%;
	margin-bottom: 10%;
}

.wrapper svg {
	margin-top: 10%;
	width: 50%;
}
.heading {
	color: var(--paragraph-color);
	font-size: calc(var(--paragraph-size) + 1.3vw);
	margin-top: 10%;
	text-align: center;
	font-weight: var(--font-weight);
}

.card {
	width: 100%;
	height: 100%;
	border-radius: 50px;
	border: 1px solid grey;
	transition: 0.3s ease-in-out;
}
.headingWrapper {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.cardHeading {
	text-align: center;
	font-size: 2.5vw;
	color: white;
}
.layout {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-flow: row;
	gap: 3%;
	margin-top: 10%;
	margin-bottom: 10%;
}

.gridItem {
	display: inline-grid;
}

.card:hover {
	cursor: pointer;
	transform: translate(0, -5%);
}

@media screen and (max-width: 400px) {
	.layout {
		grid-template-columns: minmax(0, 1fr);
		margin-bottom: 80%;
	}
	.cardHeading {
		font-size: 6vw;
	}
	.wrapper svg {
		width: 80%;
	}
}
