.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}
.heading {
	font-size: calc(var(--paragraph-size) + 1.3vw);
	color: var(--paragraph-color);
	font-weight: 350;
	letter-spacing: 0.08rem;
	margin-top: 15%;
	margin-bottom: 5%;
	justify-self: center;
}

.card {
	background-color: white;
	padding: 5%;
	padding-top: 1%;
	border-radius: 5%;
	justify-content: flex-start;
	align-items: flex-start;
	width: 30%;
	display: flex;
	flex-direction: column;
	min-height: 75vh;
}
.cardHeading {
	color: var(--primary);
	text-align: center;
	font-style: italic;
	font-weight: var(--font-weight);
	font-size: 2.4vw;
	width: 100%;
}
.cardSubheading {
	color: var(--skill-card-subheading);
	text-align: center;
	font-style: italic;
	min-height: 8vh;
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 50vh;
}
.innerRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 50vh;
}

.headingHr,
.subheadingHr {
	margin-left: -10%;
	margin-right: -10%;
	border: 0;
	height: 0;
}
.headingHr {
	border-top: 3px solid var(--primary);
}
.subheadingHr {
	border-top: 1px solid var(--primary);
}

.bodyHeading {
	color: var(--primary);
	font-size: 1.7vw;
	font-weight: calc(var(--font-weight) + 100);
	margin: 0;
	margin-bottom: 5%;
}

.outerList {
	color: var(--paragraph-color);
	font-style: italic;
	line-height: 1.8vw;
	height: 100%;
}
.outerList li {
	font-weight: 400;
}
.innerList li {
	font-weight: 300;
}
.innerList {
	margin-left: 25%;
}

.row svg {
	width: 80%;
}

.col {
	width: 100%;
	flex: 1;
	justify-content: flex-start;
	align-items: flex-start;
}

@media screen and (max-width: 400px) {
	.row {
		flex-direction: column;
		min-height: 20vh;
	}
	.card {
		min-height: 20vh;
		width: 85%;
		margin-bottom: 20%;
	}
	.cardHeading {
		font-size: 5vw;
	}
	.cardSubheading {
		font-size: 3.5vw;
		min-height: 4vh;
	}
	.headingHr,
	.subheadingHr {
		margin-left: 0;
		margin-right: 0;
	}
	.bodyHeading {
		font-size: 5vw;
	}
	.outerList {
		line-height: 8vw;
		margin-left: 5%;
		font-size: 3.7vw;
	}
	.innerList {
		margin-left: 15%;
	}
}
@media screen and (max-width: 850px) and (min-width: 400px) {
	.card {
		min-height: 130vh;
	}
	.outerList {
		line-height: 3vw;
		margin-left: 5%;
		font-size: 2vw;
	}
}
