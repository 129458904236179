.wrapper {
	margin-top: 5%;
	display: flex;
	flex-direction: column;
}
.form {
	display: flex;
	flex-direction: column;
}
.form input,
textarea {
	--rad: 20px;

	background-color: white;
	border-radius: var(--rad);
	border: 1px solid grey;
	padding: 3% 10%;
	margin-top: 2.5%;
	margin-bottom: 5%;
	font-family: "Lato";
	resize: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.button {
	margin-top: 5%;
	background-color: white;
	color: var(--primary);
	padding: 2.5% 4%;
	border-radius: 30px;
	font-style: italic;
	font-weight: var(--font-weight);
	font-size: var(--paragraph-size);
	border: 2px solid var(--primary);
}

.popup {
	padding-top: 0.5%;
	padding-bottom: 0.5%;
	border-radius: 10px;
	width: 15%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	background-color: lightgreen;
	align-self: center;
	margin-bottom: 10%;
	color: var(--paragraph-color);
	font-style: italic;
	transition: 0.3s ease-in-out;
	opacity: 0;
	top: 5%;
	left: 50%;
	margin-left: -7.5%;
}

@media screen and (max-width: 400px) {
	.popup {
		width: 50%;
		padding-top: 2%;
		padding-bottom: 2%;
		left: 50%;
		margin-left: -25%;
	}
}
