.blogCardsLayout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    place-items: center;
    gap: 1%;
    row-gap: 2.5%;
    margin-top: 5%;
}
.card {
    background-color: white;
    border-radius: 5%;
    max-width: 100%;
    min-height: 100%; 
    display: flex;
    flex-direction: column;
    margin: 2%;
}

.thumbnail {
    width: 100%;
    border-radius: 2%;
}

.title {
    font-size: larger;
    align-self: center;
    justify-self: center;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
    color: var(--paragraph-color);
}

.faded {
    background-image: linear-gradient(45deg, var(--subheading-color), var(--background));
    background-size: 100%;
    background-clip: text;
    color: transparent;
}

.description {
    padding: 3%;
    color: var(--subheading-color);
    word-spacing: 1.2px;
    line-height: 120%;
    text-rendering: optimizeSpeed;
}

.link {
    color: var(--primary);
    word-spacing: 1.2px;
}

@media screen and (max-width: 400px) {
    .blogCardsLayout {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;
        place-items: center;
        gap: 1%;
        row-gap: 2.5%;
        margin-top: 12%;
    }
}

@media screen and (max-width: 850px) and (min-width: 400px) {
    .blogCardsLayout {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
        place-items: center;
        gap: 1%;
        row-gap: 2.5%;
        margin-top: 5%;
    }
}
