/* Variables */
:root {
	--card-overlay-black: rgba(0, 0, 0, 0.53);
	--project-card-title: #ffffff;
	--placeholder-color: #b4b4b4;
	--paragraph-color: #434343;
	--skill-card-subheading: #525151;
	--background: #f0f0f0;
	--subheading-color: #707070;
	--primary: #16c389;

	--body-padding: 5%;
	--paragraph-size: 1.2vw;
	--prof-sep-heading-size: 4vw;
	--font-weight: 350;
}

/* reset */

li,
a,
ul,
p {
	margin: 0;
	padding: 0;
	text-decoration: none;
}

.base {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding-left: var(--body-padding);
	padding-right: var(--body-padding);

	overflow-x: hidden;
}

.button {
	background-color: white;
	color: var(--primary);
	padding: 1% 4%;
	border-radius: 30px;
	font-style: italic;
	font-weight: var(--font-weight);
	font-size: 1.5vw;
	border: 2px solid var(--primary);
}

.button:hover {
	cursor: pointer;
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

body {
	margin: 0;
	background-color: var(--background);

	font-family: "Lato";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 440px) {
	:root {
		--paragraph-size: 3.5vw;
		--prof-sep-heading-size: 7vw;
	}
	.button {
		padding: 2% 5%;
		font-weight: 500;
		font-size: 3.5vw;
	}
}
@media screen and (max-width: 850px) and (min-width: 400px) {
	:root {
		--paragraph-size: 1.7vw;
		--prof-sep-heading-size: 7vw;
	}
}
